import {
  Button,
  DataChoice,
  Loader,
  NotificationContainer,
  offsets,
  openNotification
} from '@xq/ui-kit';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MarketData.module.scss';

import { DataProvider } from '@pages/DataSegment/dataTypes';
import useApiRequests from './useApiRequests';
import { UserContext, useVisualTourContext } from '@context';
import { noop } from '@pages/utils';
import SendAccessRequest from '@components/Modals/SendAccessRequest';
import { EXQUANCE_INSIGHTS_LICENSE_UUID, ProviderNames } from '@constants';
import { slackCall } from '@services/slack';
import { getStepsSource, visualTourTimeout } from '@services';
import useProviderName from '@hooks/useProviderName';

export const MarketData: FC = () => {
  const navigate = useNavigate();
  const userData = useContext(UserContext);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedProvider, setSelectedProvider] = useState<string>(null);
  const [segments, setSegments] = useState<any[] | []>([]); //ProviderSegment[]
  const exquanceInsightsLicense = userData?.licenseProducts.find(
    (license) => license.uuid === EXQUANCE_INSIGHTS_LICENSE_UUID
  );
  const providerName = useProviderName(selectedProvider as ProviderNames);

  const {
    setCurrentVisualTourState,
    currentVisualTourState: { tourActive }
  } = useVisualTourContext();

  useEffect(() => {
    if (tourActive) {
      setTimeout(() => {
        setCurrentVisualTourState({ run: true });
      }, visualTourTimeout);
    }
  }, [segments, tourActive]);

  const { getSegments } = useApiRequests();
  const onCardClick = (id: string, provider: string) => {
    navigate(`${id}/${provider}`);
  };

  const onKpiSetClick = ({
    set,
    provider,
    segment
  }: {
    set: string;
    provider: string;
    segment: string;
  }) => {
    navigate(`${segment}/${provider}/${set}`);
  };

  const onRequestClick = (name: string) => {
    setIsModalOpen(true);
    setSelectedProvider(name);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onModalSubmit = async () => {
    openNotification(
      !exquanceInsightsLicense?.isTrial ? (
        <>
          Your request for access to&nbsp;
          <b className={styles.providerName}>{providerName}</b> <b>data set</b>{' '}
          has been sent. Our manager will contact you soon
        </>
      ) : (
        `Your request to buy full access to ${providerName} data set has been sent. Our manager will contact you soon.`
      ),
      'success',
      4000
    );
    let text = exquanceInsightsLicense?.isTrial
      ? `>*@REQUEST INSIGHTS FULL ACCESS:*\n`
      : `>*@REQUEST INSIGHTS TRIAL:*\n`;

    text += `>*User name:* ${userData?.firstName} ${userData?.lastName}\n`;
    text += `>*email:* ${userData?.email.trim()}\n`;
    text += `>*Organization:* ${userData.organizations
      .map((org) => org.name)
      .join(',')}\n`;
    text += `>*Data provider:* ${providerName}\n`;

    await slackCall(text);
    onModalClose();
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const { dataProviderSegments } = await getSegments();
      setSegments(dataProviderSegments);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const showQuickStartGuide = () => {
    const stepsSource = getStepsSource();
    setCurrentVisualTourState({
      run: true,
      tourActive: true,
      steps: stepsSource
    });
  };

  if (isLoading) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader color="orange" size="xl" />
      </div>
    );
  }

  if (!isLoading && !segments.length) {
    return (
      <div className={styles.noDataWrapper}>
        <p>No data</p>
      </div>
    );
  }

  const desiredOrder = ['Office', 'Logistic', 'Residential'];

  segments.sort(
    (a: { name: string }, b: { name: string }) =>
      desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)
  );

  return (
    <div style={{ display: 'relative' }}>
      <div className={styles.quickStartGuide}>
        <Button
          className={offsets['mr-20']}
          icon={'book-upload'}
          type={'fourth'}
          onClick={showQuickStartGuide}
          disabled={!exquanceInsightsLicense}
        >
          Quick start guide
        </Button>
      </div>

      <div className={styles.cardsWrapper} id={'main-page-segment-cards'}>
        {segments?.map((segment) => {
          return (
            <DataChoice
              key={segment.id}
              id={segment.id.toLowerCase()}
              cardTitle={segment.name}
              isTrial={exquanceInsightsLicense?.isTrial}
              dataVariants={segment.dataProviders.map(
                (provider: DataProvider) => ({
                  name: provider.dataProvider.toLowerCase(),
                  id: provider.dataProvider,
                  available: exquanceInsightsLicense,
                  options: provider.kpiSets
                })
              )}
              actualDate={new Date(segment.lastPublishDate)}
              onCardClick={
                exquanceInsightsLicense
                  ? () =>
                      onCardClick(
                        segment.id?.toLowerCase(),
                        segment.dataProviders?.[0]?.dataProvider?.toLowerCase()
                      )
                  : noop
              }
              onDataProviderClick={() => {}}
              onKpiSetClick={(kpi, provider) =>
                onKpiSetClick({
                  set: kpi,
                  segment: segment.id.toLowerCase(),
                  provider
                })
              }
              onRequestClick={onRequestClick}
            />
          );
        })}
      </div>
      <SendAccessRequest
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalSubmit={onModalSubmit}
        selectedProvider={selectedProvider}
        isTrial={exquanceInsightsLicense?.isTrial}
      />
      <NotificationContainer />
    </div>
  );
};

MarketData.displayName = 'MarketData';
