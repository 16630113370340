import React, { useCallback, useEffect, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import { config } from '@config';
import { heatmapColors } from '@constants';
import { initialState, reducer } from '@pages/DataSegment/reducers/map';
import useGetUrlParams from '@pages/DataSegment/hooks/useGetUrlParams';
import Legend from '@components/Map/Legend';
import Heatmap from '@components/Map/Heatmap';
import {
  HeatmapItem,
  LegendItem,
  MapShape,
  NewsecSegments
} from '../dataTypes';
import useApiRequests from './useApiRequests';
import { NewsecDashboardPageControllerGetMarketDataAreaListBySegmentNewsecSegmentEnum } from '@xq/exquance-insights-gateway-frontend-client';

interface Params {
  kpiKeys: string[];
  nameOnChart?: string;
  unit?: string;
  kpiMappings: { [key: string]: string };
}

const useDataSegmentMap = ({
  kpiKeys,
  nameOnChart,
  unit,
  kpiMappings
}: Params) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchParams, setSearchParams] = useSearchParams();
  const { fetchMapData, fetchHeatMapData } = useApiRequests();
  const {
    selectedSubmarket,
    selectedKpi,
    selectedDate,
    isMapOpen,
    segmentId,
    providerName
  } = useGetUrlParams();

  const showMap = !!isMapOpen;
  const mapFullscreen = state.mapFullscreen;
  const realKeys = kpiKeys.map((key) => key.replace('_fake', ''));

  const colorIndex = realKeys?.indexOf(selectedKpi);

  const setMapLegend = (legend: LegendItem[]) => {
    dispatch({ type: 'setMapData', payload: { legend } });
  };

  const setHeatmap = (heatmap: HeatmapItem[]) => {
    dispatch({ type: 'setMapData', payload: { heatmap } });
  };

  const handleShowMap = () => {
    if (!showMap) {
      searchParams.set('isMapOpen', 'true');
    } else {
      searchParams.delete('isMapOpen');
    }
    setSearchParams(searchParams);
  };

  const fetchHeatMap = useCallback(async () => {
    try {
      if (!kpiMappings[selectedKpi]) return;

      const response = await fetchHeatMapData({
        newsecSegment: segmentId as NewsecSegments,
        kpi: kpiMappings[selectedKpi],
        date: new Date(selectedDate)
      });

      setMapLegend(response.legend);
      setHeatmap(response.heatmap);
    } catch (error) {
      console.log(error);
    }
  }, [segmentId, selectedKpi, selectedDate, kpiMappings]);

  useEffect(() => {
    if (showMap && segmentId && selectedKpi && selectedDate && kpiMappings) {
      fetchHeatMap();
    }
  }, [segmentId, selectedKpi, showMap, selectedDate, kpiMappings]);

  const handleCloseMap = () => {
    searchParams.delete('isMapOpen');
    setSearchParams(searchParams);
    dispatch({
      type: 'setMapData',
      payload: { mapFullscreen: false }
    });
  };

  const handleFullscreenMap = () => {
    dispatch({ type: 'toggleFullScreen' });
  };

  const showHeatmap = state.legend.length > 0 && selectedDate && selectedKpi;

  const getShapeColor = useCallback(
    (submarket: string) => {
      const order = state.heatmap?.find(
        (item: HeatmapItem) => item?.submarket === submarket
      )?.rangeOrder;
      if (!order) {
        return '#DADADA';
      }

      return heatmapColors[colorIndex]?.[order - 1];
    },
    [colorIndex, state.heatmap]
  );

  const mapOptions = {
    center: state.activeShape
      ? {
          lat: state.activeShape?.center?.lat ?? 0,
          lng: state.activeShape?.center?.lng ?? 0
        }
      : { lat: 60.20579, lng: 24.846735 },
    shapes: state.shapes?.map((shape) => {
      const coordinates = shape.geometry.coordinates.map(
        (coordinates: number[]) => ({
          lat: coordinates?.[0],
          lng: coordinates?.[1]
        })
      );

      return {
        id: shape.gid,
        submarket: shape.submarket,
        strokeColor: getShapeColor(shape.submarket) || '#0063F7',
        fillColor: getShapeColor(shape.submarket) || '#0063F7',
        fillOpacity: 0.6,
        coordinates,
        onClick: () => {
          searchParams.set('submarket', shape.submarket);
          setSearchParams(searchParams);
        }
      };
    }),
    bottomData: showHeatmap ? (
      <Heatmap legend={state.legend} colorIndex={colorIndex} />
    ) : null,
    headerData: (
      <Legend
        providerName={providerName}
        nameOnChart={nameOnChart}
        segmentId={segmentId}
        unit={unit}
        selectedDate={selectedDate}
      />
    ),
    activeShape: state.activeShape,
    onFullscreenClick: handleFullscreenMap,
    onCloseClick: handleCloseMap,
    googleMapsApiKey: config.googleMapsApiKey
  };

  const fetchMapShapes = async (segmentId: string) => {
    try {
      const { items } = await fetchMapData(
        segmentId as NewsecDashboardPageControllerGetMarketDataAreaListBySegmentNewsecSegmentEnum
      );
      dispatch({ type: 'setMapData', payload: { shapes: items } });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showMap && selectedSubmarket) {
      fetchMapShapes(segmentId);
    }
  }, [showMap, segmentId]);

  useEffect(() => {
    if (showMap && selectedSubmarket && state.shapes.length > 0) {
      const activeShape = state.shapes?.find(
        (shape: MapShape) => shape.submarket === selectedSubmarket
      );

      const kpiValue = state.heatmap?.find(
        (item: HeatmapItem) => item.submarket === activeShape?.submarket
      )?.formattedValue;

      dispatch({
        type: 'setMapData',
        payload: {
          activeShape: {
            id: activeShape?.gid,
            center: {
              lat: activeShape?.geometry?.coordinates?.[0]?.[0] ?? 0,
              lng: activeShape?.geometry?.coordinates?.[0]?.[1] ?? 0
            },
            kpi: selectedKpi,
            ...(kpiValue ? { kpiValue } : {})
          }
        }
      });
    }
  }, [selectedSubmarket, showMap, state.shapes, state.heatmap]);

  return {
    mapOptions,
    handleShowMap,
    handleFullscreenMap,
    handleCloseMap,
    showMap: !!isMapOpen,
    mapFullscreen
  };
};

export default useDataSegmentMap;
