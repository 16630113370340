import { SlackControllerApi } from '@xq/exquance-insights-gateway-frontend-client';
import { exquanceInsightsApiConfiguration } from '@services/request';

const slackController = new SlackControllerApi(
  exquanceInsightsApiConfiguration
);

const slackCall = async (text: string) => {
  return await slackController.slackControllerSendMessage({
    slackPostMessageRequest: {
      text
    }
  });
};

export { slackCall };
