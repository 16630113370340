import React, { ReactElement } from 'react';
import styles from './Map.module.scss';
import { Icon } from '@xq/ui-kit';
import { capitalizeFirstLetter } from '@services';
import useProviderName from '@hooks/useProviderName';
import dayjs from 'dayjs';
import { ProviderNames } from '@constants';

interface Props {
  providerName: string;
  segmentId: string;
  nameOnChart: string;
  unit: string;
  selectedDate: number;
}

function Legend({
  providerName,
  segmentId,
  nameOnChart,
  unit,
  selectedDate
}: Props): ReactElement {
  const formattedValue = useProviderName(
    providerName.toLowerCase() as ProviderNames
  );

  return (
    <div className={styles.legend}>
      <div>
        <Icon name="build" size="s" className={styles.icon} />
        {formattedValue}: {capitalizeFirstLetter(segmentId)}
      </div>
      {nameOnChart && (
        <div>
          <Icon name="activity" size="s" className={styles.icon} />
          <div>{nameOnChart}</div>
          {unit && (
            <div>
              <div className={styles.splitter} />
              {unit}
            </div>
          )}
        </div>
      )}
      {selectedDate > 0 && (
        <div>
          <Icon name="calendar" size="s" className={styles.icon} />
          {dayjs(selectedDate).format('DD.MM.YYYY')}
        </div>
      )}
    </div>
  );
}

export default Legend;
