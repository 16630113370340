import { ChartData } from '@pages/DataSegment/dataTypes';

interface State<T> {
  data: T;
  isLoading: boolean;
  chartKpiMappings: { [key: string]: string };
}

type ActionName = 'setData' | 'setLoading' | 'setChartKpiMappings';

interface Action<T> {
  type: ActionName;
  payload: T;
}

type DataType = ChartData | null;

const initialState: State<DataType> = {
  data: null,
  chartKpiMappings: {},
  isLoading: false
};

function reducer<T>(state: State<DataType>, action: Action<T>) {
  const { type } = action;

  switch (type) {
    case 'setData':
      return { ...state, data: action.payload as ChartData };
    case 'setChartKpiMappings':
      return {
        ...state,
        chartKpiMappings: action.payload as { [key: string]: string }
      };
    case 'setLoading':
      return { ...state, isLoading: action.payload as boolean };
    default:
      return state;
  }
}

export { initialState, reducer, ActionName };
