import { useParams, useSearchParams } from 'react-router-dom';

function useGetUrlParams() {
  const [searchParams] = useSearchParams();
  const { segment_id, provider_name, set_id } = useParams();

  const selectedSubmarket: string = searchParams.get('submarket');
  const selectedKpiGroup: string = searchParams.get('kpiGroup');
  const isDatesMode: boolean = searchParams.get('mode') === 'dates';
  const isKpiArray: boolean = searchParams.get('kpiArray') === 'true';
  const selectedKpi = searchParams.get('kpi');
  const isMapOpen = searchParams.get('isMapOpen');
  const selectedDate = Number(searchParams.get('date'));

  return {
    selectedSubmarket,
    selectedKpiGroup,
    isDatesMode,
    isKpiArray,
    selectedKpi,
    selectedDate,
    isMapOpen,
    segmentId: segment_id,
    providerName: provider_name,
    setId: set_id
  };
}

export default useGetUrlParams;
