import { MarketDataSegment } from '@xq/exquance-insights-gateway-frontend-client';
import { ApiTabs, KpiVariants } from '../dataTypes';

export type DataSegmentState = {
  segments: MarketDataSegment[];
  tabsInfo: ApiTabs;
  activeTab: KpiVariants;
  activeDate: number | null;
};

type SetTableInfoAction = {
  type: 'setTableInfo';
  field: ApiTabs;
  activeTab: KpiVariants | null;
};

type SetDataSegmentDataAction = {
  type: 'setDataSegmentData';
  payload: Partial<DataSegmentState>;
};

type Action = SetTableInfoAction | SetDataSegmentDataAction;

function reducer(state: DataSegmentState, action: Action) {
  const { type } = action;
  switch (type) {
    case 'setDataSegmentData':
      return { ...state, ...action.payload };

    case 'setTableInfo':
      return { ...state, tabsInfo: action.field, activeTab: action.activeTab };

    default:
      return state;
  }
}

interface State {
  segments: MarketDataSegment[];
  tabsInfo: ApiTabs;
  activeTab: KpiVariants;
  activeDate: number | null;
}

const initialState: State = {
  segments: [],
  tabsInfo: null,
  activeTab: null,
  activeDate: null
};

export { reducer, initialState };
