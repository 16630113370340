import { exquanceInsightsApiConfiguration } from '@services';
import {
  NewsecDashboardPageApi,
  NewsecDashboardPageControllerGetMarketDataAreaListBySegmentNewsecSegmentEnum
} from '@xq/exquance-insights-gateway-frontend-client';
import {
  FetchChartDataParams,
  FetchHeatMapDataParams,
  FetchSubmarketDataParams,
  FetchTableColsDataParams,
  FetchTableRowsParams,
  NewsecSegments,
  OptionsParams,
  SubmarketOptionsParams
} from '../dataTypes';
import interceptor from '@services/http';

const useApiRequests = () => {
  const newsecGateway = new NewsecDashboardPageApi(
    exquanceInsightsApiConfiguration
  );

  const getKpiSets = async (segment: NewsecSegments) => {
    const res = await interceptor(
      async () =>
        await newsecGateway.newsecDashboardPageControllerGetPageDataRaw({
          newsecSegment: segment
        })
    );
    return res.raw.json();
  };

  const fetchTableRowsData = async (params: FetchTableRowsParams) => {
    const {
      kpi,
      sortOrder,
      sortBy,
      searchString,
      segment,
      isPercentView,
      date
    } = params;
    const optionsObject: OptionsParams = {
      sort: [
        {
          order: sortOrder,
          fieldName: sortBy
        }
      ],
      isPercentView,
      searchString,
      date
    };

    return interceptor(
      async () =>
        await newsecGateway.newsecDashboardPageControllerGetSubMarketList({
          kpiSet: kpi,
          newsecSegment: segment,
          newsecListOptionsDTORequest: optionsObject
        })
    );
  };

  const fetchTableColsData = async ({
    kpi,
    segment
  }: FetchTableColsDataParams) => {
    return interceptor(
      async () =>
        await newsecGateway.newsecDashboardPageControllerGetSubMarketTableColumns(
          {
            kpiSet: kpi,
            newsecSegment: segment
          }
        )
    );
  };

  const fetchChartData = async ({
    selectedSubmarket,
    segment,
    kpiGroup = undefined,
    kpi = undefined
  }: FetchChartDataParams) => {
    return await interceptor(
      async () =>
        await newsecGateway.newsecDashboardPageControllerGetSubMarketChartData({
          selectedSubmarket,
          kpiGroup,
          kpi,
          newsecSegment: segment
        })
    );
  };

  const fetchMapData = async (
    segment: NewsecDashboardPageControllerGetMarketDataAreaListBySegmentNewsecSegmentEnum
  ) => {
    return interceptor(
      async () =>
        await newsecGateway.newsecDashboardPageControllerGetMarketDataAreaListBySegment(
          {
            newsecSegment: segment
          }
        )
    );
  };

  const fetchSubmarketData = async (params: FetchSubmarketDataParams) => {
    const {
      kpi,
      sortOrder,
      sortBy,
      selectedSubmarket,
      segment,
      isPercentView
    } = params;
    const optionsObject: SubmarketOptionsParams = {
      sort: [
        {
          order: sortOrder,
          fieldName: sortBy
        }
      ],
      isPercentView,
      selectedSubmarket
    };

    return interceptor(
      async () =>
        await newsecGateway.newsecDashboardPageControllerGetListForSubmarket({
          kpiSet: kpi,
          newsecSegment: segment,
          newsecListForSubmarketOptionsDTORequest: optionsObject
        })
    );
  };

  const fetchHeatMapData = async (params: FetchHeatMapDataParams) => {
    return interceptor(
      async () =>
        await newsecGateway.newsecDashboardPageControllerGetHeatmap(params)
    );
  };

  return {
    getKpiSets,
    fetchTableRowsData,
    fetchTableColsData,
    fetchChartData,
    fetchSubmarketData,
    fetchMapData,
    fetchHeatMapData
  };
};

export default useApiRequests;
