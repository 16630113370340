import { ProviderNames } from '@constants';

function useProviderName(selectedProvider: ProviderNames) {
  switch (selectedProvider) {
    case ProviderNames.newsec:
      return 'Newsec Advisory Finland';
    default:
      return selectedProvider;
  }
}

export default useProviderName;
