import {
  NewsecListSortOptionsDTOFieldNameEnum,
  NewsecTableColumn,
  NewsecTableItemRow
} from '@xq/exquance-insights-gateway-frontend-client';
import {
  changeTableDataType,
  TableColumn,
  TableRow,
  Typography
} from '@xq/ui-kit';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import { initialState, reducer } from '@pages/DataSegment/reducers/table';
import {
  ApiRowItem,
  ColumnsChange,
  KpiVariants,
  NewsecSegments,
  RowFields
} from '../dataTypes';
import useApiRequests from './useApiRequests';
import { noop } from '@pages';
import useGetUrlParams from '@pages/DataSegment/hooks/useGetUrlParams';
import {
  createComparisonTypography,
  createTypography
} from '@pages/DataSegment/helpers/table';
import styles from '@pages/DataSegment/DataSegment.module.scss';

const useDataSegmentTable = ({ kpiKeys }: { kpiKeys: string[] }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchParams, setSearchParams] = useSearchParams();
  const { fetchTableRowsData, fetchTableColsData, fetchSubmarketData } =
    useApiRequests();
  const {
    selectedSubmarket,
    selectedKpiGroup,
    selectedKpi,
    selectedDate,
    isDatesMode,
    segmentId,
    setId,
    isMapOpen
  } = useGetUrlParams();

  const resetState = () => {
    dispatch({ type: 'reset' });
  };

  const setTableData = (data: ApiRowItem[]) => {
    dispatch({ type: 'setTableData', payload: data });
  };

  const onTableDataType = () => {
    dispatch({
      type: 'setDataSegmentData',
      payload: { tableDataType: changeTableDataType(state.tableDataType) }
    });
  };

  const handleDateClick = useCallback(
    (date: number) => {
      if (date === selectedDate) {
        searchParams.delete('date');
      } else {
        searchParams.set('date', String(date));
      }
      setSearchParams(searchParams);
    },
    [searchParams, selectedDate, setSearchParams]
  );

  const onRowClick = (row: TableRow) => {
    dispatch({
      type: 'setDataSegmentData',
      payload: { selectedRow: state.selectedRow?.id === row?.id ? null : row }
    });

    if (isDatesMode) {
      return handleDateClick(Number(row.id));
    }
    if (selectedSubmarket !== row.id) {
      searchParams.set('submarket', row.id);
    }

    if (selectedSubmarket === row.id && selectedKpiGroup) {
      searchParams.set('date', String(row.data.date));
      searchParams.set('mode', 'dates');
    }

    if (selectedSubmarket === row.id && !selectedKpiGroup) {
      searchParams.delete('submarket');
      searchParams.delete('date');
    }

    setSearchParams(searchParams);
  };

  const toggleClick = ({ key, isArray }: { key: string; isArray: boolean }) => {
    if (selectedKpiGroup === key) {
      searchParams.delete('kpiGroup');
      searchParams.delete('kpiArray');
      searchParams.delete('kpi');
    } else {
      searchParams.set('kpiGroup', key);
      searchParams.delete('kpi');

      if (isArray) {
        searchParams.set('kpiArray', 'true');
      } else {
        searchParams.delete('kpiArray');
      }
    }

    setSearchParams(searchParams);
  };

  const getTableCols = (
    apiCols: NewsecTableColumn[],
    selectedKpiGroup: string
  ): TableColumn[] => {
    const cols: TableColumn[] = [];
    apiCols.map((col: NewsecTableColumn, i: number) => {
      const colToggle =
        col.type === 'number'
          ? {
              toggle: {
                key: col.key + 'Toggle',
                title: col.title,
                unit: col.unit || null,
                ...(col.key === selectedKpiGroup && {
                  className: styles.defaultCursor
                }),
                onToggle:
                  col.key === selectedKpiGroup
                    ? noop
                    : (key: string) =>
                        toggleClick({ key, isArray: col.isGroup })
              }
            }
          : {};

      cols.push({
        alignment: i === 0 ? 'left' : 'right',
        paddingLeft: 20,
        sortable: col.isSortable,
        ...colToggle,
        ...col,
        title: col.type === 'number' ? '' : col.title,
        fixed: col.key === 'submarket'
      });

      if (col.type === 'number') {
        cols.push({
          title: `Comparison to ${
            state.comparisonDate
              ? dayjs(state.comparisonDate).format('DD.MM.YYYY')
              : '...'
          }`,
          key: `${col.key}-comparison`,
          alignment: 'left',
          paddingLeft: 20,
          sortable: false
        });
      }
    });
    return cols;
  };

  const getTableRows = (
    apiRows: ApiRowItem[],
    columnsChange: ColumnsChange
  ): TableRow[] => {
    const newRows: TableRow[] = [];
    apiRows.forEach((row: ApiRowItem) => {
      const newRow = createNewRow(row, isDatesMode);
      newRows.push(newRow);

      addValueToRow(newRow, row);
      addComparisonToRow(newRow, row, columnsChange);
    });

    return newRows;
  };

  const createNewRow = (row: ApiRowItem, isDatesMode: boolean): TableRow => {
    const submarketName = row.fields.find(
      (field: { fieldName: string }) => field.fieldName === 'submarket'
    )?.values?.[0].value;

    return {
      id: isDatesMode
        ? String(dayjs(row?.publishDate).valueOf())
        : String(submarketName),
      data: {
        date: isDatesMode ? dayjs(row?.publishDate).valueOf() : selectedDate,
        ...(isDatesMode
          ? {
              publishDate: (
                <Typography variant="number-2">
                  {dayjs(row?.publishDate).format('DD.MM.YYYY')}
                </Typography>
              )
            }
          : {
              submarket: (
                <Typography variant="number-2">{submarketName}</Typography>
              )
            })
      }
    };
  };

  const addValueToRow = (newRow: TableRow, row: ApiRowItem) => {
    const isSelectedRow =
      selectedSubmarket === newRow.id || selectedDate === Number(newRow.id);
    row.fields?.forEach((item) => {
      if (item.fieldName === 'submarket') return;
      const isActiveCell = item.fieldName === selectedKpiGroup && isSelectedRow;
      newRow.data[item.fieldName] = createTypography(
        item.values.map(
          (item: {
            value: number;
            allowZeroValue: boolean;
            formattedValue: string;
          }) => ({
            value: item.value,
            formattedValue:
              item.value === 0 && !item.allowZeroValue
                ? null
                : item.formattedValue
          })
        ),
        isActiveCell,
        selectedKpi,
        kpiKeys.map((key) => key.replace('_fake', ''))
      );
    });
  };

  const addComparisonToRow = (
    newRow: TableRow,
    row: ApiRowItem,
    columnsChange: ColumnsChange
  ) => {
    row.fields?.forEach((item: RowFields) => {
      newRow.data[`${item.fieldName}-comparison`] = createComparisonTypography(
        item.fieldName,
        item.comparison.map(
          (item: {
            value: number;
            allowZeroValue: boolean;
            formattedValue: string;
          }) => ({
            value: item.value,
            formattedValue: item.formattedValue
          })
        ),
        columnsChange
      );
    });
  };
  const isLoading = state.isTableLoading;
  const tableRows = useMemo(
    () =>
      getTableRows(
        state.tableData,
        state.tableColumns.reduce(
          (acc, column) => ({
            ...acc,
            [column.key]: column.isChangePositive
          }),
          {}
        )
      ),
    [
      state.tableData,
      state.tableColumns,
      selectedSubmarket,
      selectedKpi,
      selectedKpiGroup,
      selectedDate
    ]
  );

  const tableCols = getTableCols(state.tableColumns, selectedKpiGroup);

  const handleSort = (data: NewsecListSortOptionsDTOFieldNameEnum) => {
    dispatch({ type: 'setSort', field: data });
  };

  const fetchSubmarketDates = async () => {
    setTableData([]);
    try {
      dispatch({ type: 'setIsTableLoading', isTableLoading: true });
      const response = await fetchSubmarketData({
        kpi: setId as KpiVariants,
        sortOrder: state.sortOrder,
        sortBy: state.sortBy,
        selectedSubmarket,
        segment: segmentId as NewsecSegments,
        isPercentView: state.tableDataType === 'comparative'
      });

      setTableData(response.items as unknown as ApiRowItem[]);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: 'setIsTableLoading', isTableLoading: false });
    }
  };

  const fetchTableData = async ({
    page = 1,
    isShowLoader = true,
    externalDate
  }: {
    page?: number;
    isShowLoader?: boolean;
    externalDate?: number;
  }) => {
    try {
      if (isShowLoader) {
        dispatch({ type: 'setIsTableLoading', isTableLoading: true });
      }
      const timePoint = externalDate || selectedDate;

      const response = await fetchTableRowsData({
        kpi: setId as KpiVariants,
        sortOrder: state.sortOrder,
        sortBy: state.sortBy,
        searchString: state.searchString,
        segment: segmentId as NewsecSegments,
        isPercentView: state.tableDataType === 'comparative',
        date: timePoint ? new Date(timePoint) : undefined,
        page
      });
      if (!selectedSubmarket) {
        searchParams.set('submarket', response.selectedSubmarket);
        setSearchParams(searchParams);
      }

      // TODO: Temporary solution, to close map, when it's now values for selected submarket
      if (!response.items?.[0]?.fields?.[1]?.values?.[0]?.value) {
        searchParams.delete('isMapOpen');
      }

      dispatch({
        type: 'setDataSegmentData',
        payload: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          tableData: [
            ...(page === 1 ? [] : state.tableData),
            ...(response.items as NewsecTableItemRow[])
          ],
          defaultPublishDate: dayjs(response.publishDate).valueOf(),
          comparisonDate: response.previousPublishDate,
          page,
          noMoreData: page === response.meta.totalPages
        }
      });

      if (!selectedDate) {
        searchParams.set('date', String(dayjs(response.publishDate).valueOf()));
        setSearchParams(searchParams);
      }
    } catch (error) {
      console.error('Failed to fetch table data:', error);
    } finally {
      dispatch({ type: 'setIsTableLoading', isTableLoading: false });
    }
  };

  const onLoadMoreData = async () => {
    await fetchTableData({ page: state.page + 1, isShowLoader: false });
  };

  useEffect(() => {
    if (!setId) return;

    fetchTableColsData({
      kpi: setId as KpiVariants,
      segment: segmentId as NewsecSegments
    })
      .then((result) => {
        if (!selectedKpiGroup) {
          searchParams.set('kpiGroup', result.columns[1].key);
          if (result.columns[1].isGroup) {
            searchParams.set('kpiArray', 'true');
          }

          setSearchParams(searchParams);
        }

        dispatch({
          type: 'setDataSegmentData',
          payload: {
            tableColumns: isDatesMode
              ? result?.columns.map((column) =>
                  column.key === 'submarket'
                    ? { ...column, title: 'Date', key: 'publishDate' }
                    : column
                )
              : result.columns
          }
        });
      })
      .catch((error) => {
        console.error('Failed to fetch columns:', error);
      });
  }, [setId, segmentId]);

  useEffect(() => {
    if (!setId) return;

    if (!isDatesMode) {
      fetchTableData({ page: 1 });
    }
  }, [isDatesMode, state.tableDataType, state.sortOrder, setId, segmentId]);

  useEffect(() => {
    if (isDatesMode) {
      fetchSubmarketDates();
    }
  }, [isDatesMode, state.tableDataType, state.sortOrder]);

  useEffect(() => {
    const formattedColumn = {
      ...state.tableColumns.find(
        (column) => column.key === 'submarket' || column.key === 'publishDate'
      ),
      ...(!isDatesMode
        ? { title: 'Submarket Name', key: 'submarket' }
        : { title: 'Date', key: 'publishDate' })
    };
    dispatch({
      type: 'setDataSegmentData',
      payload: {
        tableColumns: state.tableColumns.map((column) =>
          column.key === 'submarket' || column.key === 'publishDate'
            ? formattedColumn
            : column
        ),
        ...(isDatesMode ? { defaultPublishDate: null } : {})
      }
    });
  }, [isDatesMode]);

  // Set selected row if have selected submarket
  useEffect(() => {
    if (selectedSubmarket) {
      const selectedRow = tableRows.find(
        (item) =>
          item.id === selectedSubmarket || Number(item.id) === selectedDate
      );

      dispatch({
        type: 'setDataSegmentData',
        payload: {
          selectedRow
        }
      });
    }
  }, [selectedSubmarket, tableRows, selectedDate]);

  useEffect(() => {
    if (state.selectedRow) {
      const isLastTableRow =
        tableRows[tableRows.length - 1]?.id === state.selectedRow?.id;

      const row = document?.getElementById(
        `row-${state.selectedRow?.id}`
      ) as HTMLElement | null;
      const parentElement = document.querySelector(
        '#ui-table'
      ) as HTMLElement | null;
      const rowRect = row?.getBoundingClientRect();
      const parentElementRect = parentElement?.getBoundingClientRect();
      const offsetTableHeader = parentElement
        .getElementsByTagName('thead')?.[0]
        .getBoundingClientRect().height;

      const isInViewport =
        rowRect?.top > parentElementRect?.top + offsetTableHeader &&
        rowRect?.bottom < parentElementRect.bottom;

      if (row && parentElement && !isInViewport) {
        const newScrollTop = isLastTableRow
          ? parentElement.scrollHeight
          : row.offsetTop - offsetTableHeader;

        parentElement.scrollTo({
          top: newScrollTop,
          behavior: 'smooth'
        });
      }
    }
  }, [state.selectedRow, tableRows]);

  const handleLegendItemClick = (key: string) => {
    // setSearchParams trigger rerender, so better solution to call this function inside condition block
    if (key === selectedKpi && !isMapOpen) {
      searchParams.delete('kpi');
      setSearchParams(searchParams);
    } else if (key !== selectedKpi) {
      searchParams.set('kpi', key);
      setSearchParams(searchParams);
    }
  };

  const handleChartClick = async (date: number): Promise<void> => {
    if (selectedDate === date) {
      return;
    }

    handleDateClick(date);
    // Fetch table data when click on time point, because initially we get last time point from table rows.
    // This is done to prevent double fetching data and for simplify logic
    if (!isDatesMode) {
      await fetchTableData({ page: 1, externalDate: date });
    }
  };

  return {
    getTableRows,
    getTableCols,
    onRowClick,
    toggleClick,
    activeColumn: state.activeColumn,
    tableDataType: state.tableDataType,
    onTableDataType,
    selectedRow: state.selectedRow,
    resetState,
    isLoading,
    tableRows,
    tableCols,
    handleSort,
    tableSortOrder: state.sortOrder,
    tableSortBy: state.sortBy,
    handleLegendItemClick,
    handleChartClick,
    onLoadMoreData,
    noMoreData: state.noMoreData
  };
};

export default useDataSegmentTable;
