import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Header,
  HeaderApp,
  HeaderAppNames,
  NavItem,
  NotificationContainer,
  offsets,
  openStatusNotification,
  ScrollTopButton,
  VisualTour
} from '@xq/ui-kit';
import styles from './Layout.module.scss';
import {
  NavLink,
  Outlet,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LayoutService } from './layout-service';
import { config } from '@config';
import { getRouteUrl, ROUTES } from '@router';
import { UserContext, UserContextData, useVisualTourContext } from '@context';
import {
  getLocalStorageItem,
  getMergedURLSearchParams,
  getStatusNotificationTranslations,
  getStepsSource,
  LocalStorageKey,
  logger,
  setLocalStorageItem,
  wasQuickStartGuideShownValues
} from '@services';
import { LayoutData } from './dataTypes';
import ScrollToTop from '@services/utils';
import { CallBackProps } from 'react-joyride';
import { redirectToSSOLoginPage } from '@services/http';
import { EXQUANCE_INSIGHTS_LICENSE_UUID } from '@constants';

const currentApp: HeaderApp = HeaderAppNames.Insights;

export const Layout: FC = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const service: LayoutService = new LayoutService();

  const [userData, setUserData] = useState<LayoutData>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [avatar, setAvatar] = useState<string>('');
  const [languageIso2, setLanguageIso2] = useState<string>('');
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const {
    setCurrentVisualTourState,
    currentVisualTourState: { run, stepIndex, steps }
  } = useVisualTourContext();

  const fullName = useMemo(() => {
    let name = '';
    if (firstName) {
      name += `${firstName} `;
    }
    if (lastName) {
      name += lastName;
    }
    if (!name) {
      name = 'Username';
    }
    return name;
  }, [firstName, lastName]);

  const headerNavItems = (): NavItem[] => {
    const routes = [ROUTES.MARKET_DATA.MAIN];

    return routes.map((route) => {
      return {
        name: t(route),
        path: getRouteUrl(route)
      };
    });
  };

  const profileLinks: NavItem[] = [
    {
      name: t('routes.userSettings'),
      path: config.accountFrontendUrl
    }
  ];

  const initValues = () => {
    setFirstName(userData?.firstName);
    setLastName(userData?.lastName);
    setAvatar(userData?.avatar);
    setLanguageIso2(userData?.languageIso2);
  };

  async function authCheck() {
    try {
      await service.authCheck();
      setIsAuthChecked(true);
    } catch (error) {
      setIsAuthChecked(false);
      const status = error.status;
      if (status === 401) {
        redirectToSSOLoginPage();
      } else {
        openStatusNotification({
          translations: getStatusNotificationTranslations(t),
          status: status,
          error: {
            details: error?.details,
            code: error?.error,
            message: error?.message
          }
        });
      }
    }
  }

  async function fetchData() {
    try {
      const res = await service.fetchData();
      setUserData(res);
      setIsDataFetched(true);
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  async function logout() {
    try {
      await service.logout();
      redirectToSSOLoginPage();
    } catch (error) {
      openStatusNotification({
        translations: getStatusNotificationTranslations(t),
        status: error?.status,
        error: {
          details: error?.details,
          code: error?.error,
          message: error?.message
        }
      });
    }
  }

  useEffect(() => {
    authCheck().then(() => {
      fetchData();
    });
  }, []);

  function autoRunQuickStartGuide() {
    const exquanceInsightsLicense = userData?.licenseProducts.find(
      (license) => license.uuid === EXQUANCE_INSIGHTS_LICENSE_UUID
    );

    if (!exquanceInsightsLicense) {
      return;
    }

    const wasQuickStartGuideShown = getLocalStorageItem(
      LocalStorageKey.wasQuickStartGuideShown
    );

    if (wasQuickStartGuideShown !== wasQuickStartGuideShownValues.yes) {
      const stepsSource = getStepsSource();
      navigate(getRouteUrl(ROUTES.MARKET_DATA.MAIN));
      setCurrentVisualTourState({
        run: true,
        tourActive: true,
        steps: stepsSource
      });

      setLocalStorageItem(
        LocalStorageKey.wasQuickStartGuideShown,
        wasQuickStartGuideShownValues.yes
      );
    }
  }

  useEffect(() => {
    initValues();
    autoRunQuickStartGuide();
  }, [userData]);

  const userContextData: UserContextData = {
    firstName,
    lastName,
    avatar,
    languageIso2,
    email: userData?.email,
    licenseProducts: userData?.licenseProducts || [],
    organizations: userData?.organizations || [],
    setFirstName,
    setLastName,
    setAvatar,
    setLanguageIso2
  };

  const handleVisualTourCallback = (data: CallBackProps) => {
    const { action, type } = data;

    const urlParams = getMergedURLSearchParams(
      searchParams,
      data.step.data?.urlParams
    );

    if (type === 'step:after') {
      switch (action) {
        case 'prev': {
          if (data.step.data?.previous) {
            setCurrentVisualTourState({ run: false, stepIndex: stepIndex - 1 });
            navigate(data.step.data?.previous + urlParams);
          } else {
            setCurrentVisualTourState({ stepIndex: stepIndex - 1 });
          }

          break;
        }
        case 'next': {
          if (data.step.data?.next) {
            setCurrentVisualTourState({
              run: false,
              stepIndex: stepIndex + 1
            });
            navigate(data.step.data?.next + urlParams);
          } else {
            setCurrentVisualTourState({ stepIndex: stepIndex + 1 });
          }

          break;
        }
        case 'close': {
          closeTour();

          break;
        }
      }

      if (stepIndex === steps?.length - 1 && action === 'next') {
        closeTour();
      }
    }
  };

  function closeTour() {
    setCurrentVisualTourState({ run: false, tourActive: false, stepIndex: 0 });
  }

  useEffect(() => {
    if (languageIso2) {
      i18n.changeLanguage(languageIso2);
      try {
        if (getLocalStorageItem(LocalStorageKey.lang) !== languageIso2) {
          setLocalStorageItem(LocalStorageKey.lang, languageIso2);
        }
      } catch (error) {
        logger.error('Local storage error', error);
      }
    }
  }, [languageIso2]);

  return (
    <Fragment>
      <VisualTour
        disableScrolling={true}
        disableScrollParentFix={true}
        stepIndex={stepIndex}
        runTour={run}
        steps={steps}
        visualTourCallback={handleVisualTourCallback}
        previousText={'Back'}
        nextText={'Next'}
      />
      {isAuthChecked && isDataFetched && (
        <div className={styles.layout}>
          <Header
            className={styles.header}
            apps={userData?.apps}
            navItems={headerNavItems()}
            profileLinks={profileLinks}
            avatar={avatar}
            organizations={userData?.organizations}
            currentOrganizationId={userData?.currentOrganizationId}
            name={fullName}
            currentApp={currentApp}
            NavLink={NavLink}
            onSignOut={logout}
            translations={{
              signOut: t('routes.signOut')
            }}
          />
          <main className={offsets['mb-40']}>
            <ScrollToTop />
            <UserContext.Provider value={userContextData}>
              <Outlet />
            </UserContext.Provider>
          </main>
          <NotificationContainer />
        </div>
      )}
      <ScrollTopButton />
    </Fragment>
  );
};

Layout.displayName = 'Layout';
