import React, { ReactElement, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '@context';
import { EXQUANCE_INSIGHTS_LICENSE_UUID } from '@constants';

interface Props {
  element: ReactElement;
}

export default function PrivateRoute({ element }: Props): ReactElement {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const exquanceInsightsLicense = userContext?.licenseProducts.find(
    (license) => license.uuid === EXQUANCE_INSIGHTS_LICENSE_UUID
  );

  if (!exquanceInsightsLicense) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return element;
}
