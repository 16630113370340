import dayjs from 'dayjs';
import { useEffect, useReducer } from 'react';
import { ChartData, NewsecSegments } from '../dataTypes';
import {
  ActionName,
  initialState,
  reducer
} from '@pages/DataSegment/reducers/chart';
import useApiRequests from './useApiRequests';
import useGetUrlParams from '@pages/DataSegment/hooks/useGetUrlParams';
import {
  ChartDataResponse,
  ChartItem,
  ChartKpiItem
} from '@xq/exquance-insights-gateway-frontend-client';

const useDataSegmentChart = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    segmentId,
    selectedSubmarket,
    selectedKpiGroup,
    isDatesMode,
    isKpiArray
  } = useGetUrlParams();
  const { fetchChartData } = useApiRequests();

  const chartData = state.data;
  const chartLoading: boolean = state.isLoading;

  const setChartData = <T,>(data: T, type: ActionName) => {
    dispatch({ type, payload: data });
  };

  const fetchData = async (): Promise<void> => {
    try {
      setChartData<boolean>(true, 'setLoading');

      const response: ChartDataResponse = await fetchChartData({
        selectedSubmarket: selectedSubmarket as string,
        ...(isKpiArray
          ? { kpiGroup: selectedKpiGroup as string }
          : { kpi: selectedKpiGroup as string }),
        segment: segmentId as NewsecSegments
      });
      setChartData<ChartData>(
        {
          ...response,
          data: response.data?.map((chartValue: ChartItem) => {
            return {
              date: dayjs(chartValue.publishDate).valueOf(),
              ...chartValue.values.reduce(
                (
                  acc: { [key: string]: number | string },
                  { title, value, allowZeroValue, formattedValue }
                ) => {
                  acc[`${title}${chartValue.isFakeValue ? '_fake' : ''}`] =
                    value === 0 && !allowZeroValue ? null : value;
                  acc[
                    `${title}${
                      chartValue.isFakeValue
                        ? `_fake_formattedValue=${formattedValue}`
                        : `_formattedValue=${formattedValue}`
                    }`
                  ] = value === 0 && !allowZeroValue ? null : value;
                  return acc;
                },
                {}
              )
            };
          })
        },
        'setData'
      );

      setChartData(
        response.data?.[0]?.values?.reduce(
          (acc: { [key: string]: string }, item: ChartKpiItem) => {
            acc[item.title] = item.fieldName;
            return acc;
          },
          {}
        ),
        'setChartKpiMappings'
      );
    } catch (error) {
      console.log(error);
    } finally {
      setChartData<boolean>(false, 'setLoading');
    }
  };

  useEffect((): void => {
    if (!selectedSubmarket) {
      setChartData<null>(null, 'setData');
    }
  }, [selectedSubmarket]);

  useEffect((): void => {
    if (selectedSubmarket && selectedKpiGroup) {
      fetchData();
    }
  }, [selectedSubmarket, selectedKpiGroup, isDatesMode]);

  return { chartData, chartLoading, kpiMappings: state.chartKpiMappings };
};

export default useDataSegmentChart;
