import React from 'react';
import { Typography } from '@xq/ui-kit';
import styles from '@pages/DataSegment/DataSegment.module.scss';
import { chartColors } from '@constants';
import cn from 'classnames';
import { ColumnsChange } from '../dataTypes';

export const createTypography = (
  values: {
    value: number;
    formattedValue: string;
  }[],
  isActiveCell: boolean,
  selectedKpi: string,
  kpiKeys: string[]
) => (
  <p className={styles.badges} style={{ margin: 0 }}>
    {values.map((item, index) => (
      <Typography
        key={`${item.value}-${index}`}
        style={{ '--bgColor': chartColors[index] } as React.CSSProperties}
        className={cn(styles.cell, {
          [styles.activeCell]: isActiveCell,
          [styles.activeCell_disabled]:
            selectedKpi &&
            isActiveCell &&
            index !== kpiKeys.indexOf(selectedKpi)
        })}
        variant="number-2"
      >
        {item.formattedValue || '—'}
      </Typography>
    ))}
  </p>
);

const getComparisonColor = (
  key: string,
  columnsChange: ColumnsChange,
  value: number
) => {
  if (!value) {
    return '#B3B3B3';
  }

  if (columnsChange[key]) {
    if (value >= 0) {
      return '#36B37E';
    }
    return '#FF5630';
  }

  if (!columnsChange[key] && columnsChange[key] !== undefined) {
    if (value >= 0) {
      return '#FF5630';
    }
    return '#36B37E';
  }
};

export const createComparisonTypography = (
  key: string,
  values: {
    formattedValue: string;
    value: number;
  }[],
  columnsChange: ColumnsChange
) => {
  return (
    <p className={styles.flexStart} style={{ margin: 0 }}>
      {values?.map((item, index) => {
        return (
          <Typography
            key={`${item.value}-${index}`}
            variant="number-2"
            color={getComparisonColor(key, columnsChange, item.value)}
          >
            {item.value > 0 && '+'}
            {item.formattedValue || '—'}
          </Typography>
        );
      })}
    </p>
  );
};
