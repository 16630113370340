import React, { ReactElement } from 'react';
import { Modal, Typography } from '@xq/ui-kit';
import useProviderName from '@hooks/useProviderName';
import { ProviderNames } from '@constants';
import styles from './SendAccessRequest.module.scss';

interface Props {
  isModalOpen: boolean;
  onModalClose: () => void;
  onModalSubmit: () => void;
  selectedProvider: string;
  isTrial: boolean;
}

function SendAccessRequest({
  isModalOpen,
  onModalClose,
  onModalSubmit,
  selectedProvider,
  isTrial
}: Props): ReactElement {
  const providerName = useProviderName(selectedProvider as ProviderNames);

  return (
    <Modal
      cancelText="Cancel"
      submitText={isTrial ? 'Send request' : 'Send'}
      isOpen={isModalOpen}
      onClose={onModalClose}
      onSubmit={onModalSubmit}
      title=""
      maxWidth="30rem"
      className={styles.modal}
    >
      <div className={styles.modalContentWrap}>
        <Typography
          className={styles.requestMessage}
          element="span"
          variant="body-1"
        >
          {`${
            !isTrial
              ? 'Send a request for access to'
              : 'Are you interested in purchasing full access to'
          }`}
          &nbsp;
          <b className={styles.providerName}>{providerName}</b>&nbsp;data set?
        </Typography>
      </div>
    </Modal>
  );
}

export default SendAccessRequest;
