import React from 'react';
import { OrganizationItem } from '@xq/ui-kit';
import { LayoutLicenseProductDTOResponse } from '@xq/exquance-insights-gateway-frontend-client';

export interface UserContextData {
  firstName: string;
  lastName: string;
  avatar: string;
  languageIso2: string;
  email: string;
  licenseProducts: LayoutLicenseProductDTOResponse[];
  organizations: OrganizationItem[];
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setAvatar: (value: string) => void;
  setLanguageIso2: (value: string) => void;
}

const userContextData: UserContextData = {
  firstName: '',
  lastName: '',
  avatar: '',
  email: '',
  languageIso2: '',
  organizations: [],
  licenseProducts: [],
  setFirstName: () => null,
  setLastName: () => null,
  setAvatar: () => null,
  setLanguageIso2: () => null
};

export const UserContext =
  React.createContext<UserContextData>(userContextData);
