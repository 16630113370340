import React from 'react';
import classNames from 'classnames';
import {
  DataSegment,
  Forbidden,
  InternalServerError,
  MarketData,
  NotFound
} from '@pages';
import { Layout } from '@layouts';
import { Outlet, RouteObject } from 'react-router-dom';
import { LayoutContainer, offsets } from '@xq/ui-kit';
import PrivateRoute from '@components/PrivateRoute';
import { VisualTourProvider } from '@context';

export const ROUTES = {
  MARKET_DATA: {
    MAIN: 'routes.marketData'
  },
  DATA_SEGMENT: {
    MAIN: 'routes.dataSegment',
    DATA_PROVIDER: 'routes.dataSegment.dataProvider',
    DATA_SET: 'routes.dataSegment.dataSet'
  },
  ERRORS: {
    FORBIDDEN: 'routes.errors.forbidden'
  }
};

export const getRouteUrl = (name: string, params?: Record<string, string>) => {
  const query = params?.query ? `?${params.query}` : '';

  switch (name) {
    case ROUTES.MARKET_DATA.MAIN: {
      return `/`;
    }
    case ROUTES.DATA_SEGMENT.MAIN: {
      return `/${params.segment_id}${query}`;
    }
    case ROUTES.DATA_SEGMENT.DATA_PROVIDER: {
      return `/${params.segment_id}/${params.provider_name}/${query}`;
    }
    case ROUTES.DATA_SEGMENT.DATA_SET: {
      return `/${params.segment_id}/${params.provider_name}/${params.set_id}/${query}`;
    }

    default: {
      return '/';
    }
  }
};

export const routes: RouteObject[] = [
  {
    id: 'layout',
    path: '/',
    element: (
      <VisualTourProvider>
        <Layout />
      </VisualTourProvider>
    ),
    children: [
      {
        id: 'layout',
        path: '/',
        element: (
          <LayoutContainer
            className={classNames(
              offsets['mt-32'],
              offsets['ml-20'],
              offsets['mr-20']
            )}
          >
            <Outlet />
          </LayoutContainer>
        ),
        children: [
          {
            id: ROUTES.MARKET_DATA.MAIN,
            path: ``,
            element: <MarketData />,
            children: []
          },
          {
            id: ROUTES.DATA_SEGMENT.MAIN,
            path: '/:segment_id',
            element: <PrivateRoute element={<DataSegment />} />,
            children: [
              {
                id: ROUTES.DATA_SEGMENT.DATA_PROVIDER,
                path: ':provider_name',
                element: <PrivateRoute element={<DataSegment />} />,
                children: [
                  {
                    id: ROUTES.DATA_SEGMENT.DATA_SET,
                    path: ':set_id',
                    element: <PrivateRoute element={<DataSegment />} />
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    id: 'notFound',
    path: '*',
    element: <NotFound />
  },
  {
    id: 'internalError',
    path: 'internal-error',
    element: <InternalServerError />
  },
  {
    id: ROUTES.ERRORS.FORBIDDEN,
    path: 'forbidden',
    element: <Forbidden />
  }
];
