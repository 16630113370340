import {
  HeatmapItem,
  LegendItem,
  MapShape
} from '@pages/DataSegment/dataTypes';
import { ActiveShape } from '@xq/ui-kit/lib/components/Map/dataTypes';

interface State {
  showMap: boolean;
  mapFullscreen: boolean;
  activeShape: ActiveShape & { center: { lat: number; lng: number } };
  shapes: MapShape[] | [];
  heatmap: HeatmapItem[] | [];
  legend: LegendItem[] | [];
}

interface ToggleMapAction {
  type: 'toggleMap';
}

interface ToggleFullScreenAction {
  type: 'toggleFullScreen';
}

interface SetMapDataAction {
  type: 'setMapData';
  payload: Partial<State>;
}

type Action = ToggleMapAction | ToggleFullScreenAction | SetMapDataAction;

const reducer = (state: State, action: Action) => {
  const { type } = action;

  switch (type) {
    case 'toggleMap':
      return { ...state, showMap: !state.showMap };
    case 'toggleFullScreen':
      return { ...state, mapFullscreen: !state.mapFullscreen };
    case 'setMapData':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const initialState: State = {
  showMap: false,
  mapFullscreen: false,
  activeShape: null,
  shapes: [],
  heatmap: [],
  legend: []
};

export { initialState, reducer };
