import { config } from '@config';

type ApiCall<T> = () => Promise<T>;

const interceptor = async <T>(apiCall: ApiCall<T>) => {
  try {
    return await apiCall();
  } catch (error) {
    const parsedError = await error?.response?.json();
    switch (parsedError?.status) {
      case 401:
        redirectToSSOLoginPage();
        break;
      case 403:
        redirectSegmentList();
        break;
      default:
        throw error;
    }
  }
};

export const redirectToSSOLoginPage = () => {
  const redirectUrl = encodeURIComponent(window.location.href);
  const ssoUrl = `${config.ssoFrontendLoginUrl}?redirectUrl=${redirectUrl}`;
  window.location.replace(ssoUrl);
};

export const redirectSegmentList = () => {
  window.location.replace('/');
};

export default interceptor;
