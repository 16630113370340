import { getRouteUrl, ROUTES } from '@router';
import { VisualTourStepItemProps } from '@xq/ui-kit';

const spotlightPadding = 10;

export const visualTourTimeout = 600;

const baseParams = [['kpiGroup', 'vacancyRate']];
const dataSetBaseUrlParams = new URLSearchParams(baseParams);

const mapParams = [...baseParams, ['isMapOpen', 'true']];
const dataSetMapUrlParams = new URLSearchParams(mapParams);

const noMapParams = [...baseParams, ['isMapOpen', 'null']];
const dataSetNoMapUrlParams = new URLSearchParams(noMapParams);

export const getStepsSource = (): VisualTourStepItemProps[] => {
  return [
    {
      target: '#main-page-segment-cards > :first-child',
      title: 'Welcome to the Exquance Insights overview!',
      content:
        'Market data is conveniently sorted by provider and segment. You can select specific KPI to start your research.',
      placement: 'left',
      data: {
        next: getRouteUrl(ROUTES.DATA_SEGMENT.DATA_SET, {
          segment_id: 'office',
          provider_name: 'newsec',
          set_id: 'vacancy'
        }),
        urlParams: dataSetBaseUrlParams
      }
    },
    {
      target: '#data-segment-breadcrumbs',
      content: 'You can quickly change market segments here.',
      placement: 'bottom',
      data: {
        previous: getRouteUrl(ROUTES.MARKET_DATA.MAIN)
      },
      spotlightPadding
    },
    {
      target: '#data-segment-tabs',
      content:
        'You can easily access different KPIs that are available for selected market segment.',
      placement: 'bottom',
      spotlightPadding
    },
    {
      target: '#data-segment-chart',
      content:
        "You can inspect the selected KPI's changes over time for a specific submarket on a chart.",
      placement: 'bottom',
      spotlightPadding
    },
    {
      target: '#data-segment-table',
      content:
        'Examine available submarkets and KPIs for specific date compared to previous period on a grid.',
      placement: 'top',
      spotlightPadding
    },
    {
      target: '#data-segment-table tbody tr:nth-child(1)',
      content:
        'Select different submarket by clicking on the row to inspect it on a chart.',
      placement: 'top'
    },
    {
      target: '#table-header-cell-name-1',
      content: 'Switch to another KPI to explore it on the chart.',
      placement: 'top',
      spotlightPadding
    },
    {
      target: '#table-header-icon-number',
      content:
        'You can opt to view comparison as either a percentage or an absolute value.',
      placement: 'top-right',
      spotlightPadding
    },
    {
      target: '#table-chart-show-map-icon',
      content: 'Enable map view to see selected submarket on a map.',
      placement: 'bottom-right',
      data: {
        next: getRouteUrl(ROUTES.DATA_SEGMENT.DATA_SET, {
          segment_id: 'office',
          provider_name: 'newsec',
          set_id: 'vacancy'
        }),
        urlParams: dataSetMapUrlParams
      },
      spotlightPadding
    },
    {
      target: '#data-segment-map',
      content:
        'Convenient heatmap view allows to dive deep into market research.',
      placement: 'right',
      data: {
        previous: getRouteUrl(ROUTES.DATA_SEGMENT.DATA_SET, {
          segment_id: 'office',
          provider_name: 'newsec',
          set_id: 'vacancy'
        }),
        urlParams: dataSetNoMapUrlParams
      }
    }
  ];
};

export const getMergedURLSearchParams = (
  currentParams: URLSearchParams,
  targetParams?: URLSearchParams
): string => {
  if (!targetParams) {
    return '';
  }
  const currentObj = Object.fromEntries(currentParams.entries());
  const targetObj = Object.fromEntries(targetParams.entries());

  // Merge the objects, giving priority to targetObj
  const mergedObj = { ...currentObj, ...targetObj };

  for (const key in targetObj) {
    if (targetObj[key] === 'null') {
      delete mergedObj[key];
    }
  }

  // Convert the merged object back into a URLSearchParams object
  const mergedParams = new URLSearchParams(mergedObj);
  const mergedParamsString = mergedParams.toString();

  return mergedParamsString ? `?${mergedParamsString}` : '';
};
