import { exquanceInsightsApiConfiguration } from '@services';
import { MainInfoPageApi } from '@xq/exquance-insights-gateway-frontend-client';
import interceptor from '@services/http';

const useApiRequests = () => {
  const insightsGateway = new MainInfoPageApi(exquanceInsightsApiConfiguration);

  const getSegments = async () => {
    return interceptor(async () =>
      insightsGateway.mainInfoPageControllerGetPageData()
    );
  };

  return { getSegments };
};

export default useApiRequests;
