import React, { FC, useContext, useEffect, useMemo, useReducer } from 'react';
import {
  createSearchParams,
  NavLink,
  useNavigate,
  useSearchParams
} from 'react-router-dom';

import useMainPageApiRequests from '@pages/MarketData/useApiRequests';
import {
  Breadcrumbs,
  Button,
  Map,
  Table,
  TableChart,
  TabsComponent
} from '@xq/ui-kit';
import cn from 'classnames';
import useGetUrlParams from '@pages/DataSegment/hooks/useGetUrlParams';
import { initialState, reducer } from '@pages/DataSegment/reducers/common';
import {
  getBreadcrumbsItems,
  getRenamedTabs
} from '@pages/DataSegment/helpers/breadcrumb';
import useApiRequests from './hooks/useApiRequests';
import useDataSegmentChart from './hooks/useDataSegmentChart';
import useDataSegmentMap from './hooks/useDataSegmentMap';
import useDataSegmentTable from './hooks/useDataSegmentTable';
import { KpiVariants, NewsecSegments } from './dataTypes';
import styles from './DataSegment.module.scss';
import { UserContext, useVisualTourContext } from '@context';
import { EXQUANCE_INSIGHTS_LICENSE_UUID } from '@constants';
import { visualTourTimeout } from '@services';

export const DataSegment: FC = () => {
  const { chartData, chartLoading, kpiMappings } = useDataSegmentChart();
  const [state, dispatch] = useReducer(reducer, initialState);
  const userData = useContext(UserContext);
  const exquanceInsightsLicense = userData?.licenseProducts.find(
    (license) => license.uuid === EXQUANCE_INSIGHTS_LICENSE_UUID
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    selectedSubmarket,
    selectedKpiGroup,
    selectedKpi,
    selectedDate,
    isDatesMode,
    segmentId,
    setId,
    providerName
  } = useGetUrlParams();
  const { getSegments } = useMainPageApiRequests();

  const tabsItems = useMemo(
    () => getRenamedTabs(state.tabsInfo?.kpiSets),
    [state.tabsInfo?.kpiSets]
  );

  const { getKpiSets } = useApiRequests();

  const kpiKeys = chartData?.data?.[0]
    ? Object.keys(chartData?.data?.[0]).filter(
        (key) => key !== 'date' && !key.includes('_formattedValue')
      )
    : [];

  const {
    onRowClick,
    tableDataType,
    onTableDataType,
    selectedRow,
    isLoading,
    tableRows,
    tableCols,
    handleSort,
    tableSortOrder,
    tableSortBy,
    handleLegendItemClick,
    handleChartClick,
    onLoadMoreData
  } = useDataSegmentTable({ kpiKeys });

  const { mapOptions, handleShowMap, showMap, mapFullscreen } =
    useDataSegmentMap({
      kpiKeys,
      nameOnChart: chartData?.nameOnChart,
      unit: chartData?.unit,
      kpiMappings
    });

  const breadcrumbsItems = useMemo(
    () =>
      getBreadcrumbsItems(
        state.segments,
        segmentId,
        exquanceInsightsLicense?.isTrial
      ),
    [state.segments, segmentId]
  );

  const fetchData = async () => {
    try {
      const { dataProviderSegments } = await getSegments();
      dispatch({
        type: 'setDataSegmentData',
        payload: { segments: dataProviderSegments }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const comparisonKpiKeys = JSON.stringify(kpiKeys);
  useEffect(() => {
    if (showMap && selectedDate > 0) {
      const realKeys = kpiKeys.map((key) => key.replace('_fake', ''));

      searchParams.set('kpi', realKeys?.[0]);
      setSearchParams(searchParams);
    }
  }, [comparisonKpiKeys, showMap, selectedDate]);

  useEffect(() => {
    if (!segmentId) return;

    getKpiSets(segmentId as NewsecSegments)
      .then((result) => {
        const defaultTab = setId ?? result?.defaultKpiSetKey;
        if (!setId) {
          navigate(`/${segmentId}/${providerName}/${result?.defaultKpiSetKey}`);
        }
        dispatch({
          type: 'setTableInfo',
          field: result,
          activeTab: defaultTab
        });
      })
      .catch((error) => {
        console.error('Failed to fetch tabs:', error);
      });
  }, [segmentId]);

  useEffect(() => {
    if (state.activeTab !== setId && setId) {
      dispatch({
        type: 'setDataSegmentData',
        payload: { activeTab: setId as KpiVariants }
      });
    }
  }, [dispatch, state.activeTab, setId]);

  const handleClickTab = (tab: KpiVariants) => {
    dispatch({
      type: 'setDataSegmentData',
      payload: { activeTab: tab, activeDate: null }
    });
    searchParams.delete('isMapOpen');
    setSearchParams(searchParams);

    navigate({
      pathname: `/${segmentId}/${providerName}/${tab}`,
      search: `?${createSearchParams({
        ...(showMap && { isMapOpen: 'true' }),
        submarket: selectedSubmarket
      })}`
    });
  };

  const handleBackToMarkets = () => {
    searchParams.delete('mode');
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const realKeys = kpiKeys.map((key) => key.replace('_fake', ''));

    if (showMap && !selectedKpi) {
      searchParams.set('kpi', realKeys?.[0]);
    }
    if (!showMap && selectedKpi) {
      searchParams.delete('kpi');
    }
    setSearchParams(searchParams);
  }, [showMap]);

  const {
    setCurrentVisualTourState,
    currentVisualTourState: { tourActive }
  } = useVisualTourContext();

  useEffect(() => {
    if (tourActive) {
      setTimeout(() => {
        setCurrentVisualTourState({ run: true });
      }, visualTourTimeout);
    }
  }, [breadcrumbsItems, showMap, mapFullscreen, tourActive]);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageHeaderRow}>
        <Breadcrumbs
          id="data-segment-breadcrumbs"
          items={breadcrumbsItems}
          NavLink={NavLink}
          className={styles.breadcrumbStyle}
        />
        <TabsComponent
          id={'data-segment-tabs'}
          items={tabsItems}
          activeKey={state.activeTab}
          handleClickTab={handleClickTab}
          className={styles.pageHeaderRowTabs}
        />
      </div>
      <div
        className={cn(styles.content, {
          [styles.content__showMap]: showMap,
          [styles.content__fullscreenMap]: mapFullscreen
        })}
      >
        <div className={styles.viewsContainer}>
          <div className={styles.chartContainer} id="data-segment-chart">
            <TableChart
              id="chart"
              loading={chartLoading}
              mapIconHandler={handleShowMap}
              data={chartData?.data ?? []}
              connectNulls
              unit={chartData?.unit}
              chartTitle={chartData?.nameOnChart ?? ''}
              chartSubtitle={selectedSubmarket}
              activeItem={selectedDate}
              onChartClick={handleChartClick}
              onLegendItemClick={handleLegendItemClick}
              activeLegendItem={selectedKpi}
              isMapOpen={showMap}
              chartKpiInfo={chartData?.chartKpiInfo}
            />
          </div>
          <Map
            id="mapMobile"
            wrapperClassName={cn(styles.mapWrapperClassName__mobile, {
              [styles.mapWrapperClassName__mobile_show]: showMap,
              [styles.mapWrapperClassName__mobile_fullscreen]: mapFullscreen
            })}
            mapContainerClassName={styles.mapContainerClassName}
            {...mapOptions}
          />
          {isDatesMode && (
            <Button
              onClick={handleBackToMarkets}
              type="fourth"
              icon="chevron-left"
              className={styles.backButton}
            >
              {selectedSubmarket}
            </Button>
          )}
          <Table
            id={'data-segment-table'}
            className={cn({ [styles.tableDatesMode]: isDatesMode })}
            sortBy={tableSortBy}
            sortOrder={tableSortOrder}
            onSort={handleSort}
            onRowClick={onRowClick}
            columns={tableCols}
            rows={tableRows}
            maxHeight="100%"
            selectedColumnKey={selectedKpiGroup}
            selectedRowKey={selectedRow?.id}
            tableDataType={tableDataType}
            onTableDataType={onTableDataType}
            isLoading={isLoading}
            onLoading={onLoadMoreData}
            // noMoreData true because we don't use pagination at all for tables
            noMoreData
            minWidth={700}
          />
        </div>
        <Map
          id={'data-segment-map'}
          wrapperClassName={cn(styles.mapWrapperClassName, {
            [styles.mapWrapperClassName_show]: showMap,
            [styles.mapWrapperClassName_fullscreen]: mapFullscreen
          })}
          mapContainerClassName={styles.mapContainerClassName}
          {...mapOptions}
        />
      </div>
    </div>
  );
};

DataSegment.displayName = 'DataSegment';
