import React, { ReactElement } from 'react';
import { LegendItem } from '@pages/DataSegment/dataTypes';
import { heatmapColors } from '@constants';
import styles from './Map.module.scss';

interface Props {
  legend: LegendItem[];
  colorIndex?: number;
}

function Heatmap({ legend, colorIndex }: Props): ReactElement {
  return (
    <div
      className={styles.heatmap}
      style={{ gridTemplateColumns: `repeat(${legend.length || 5}, 1fr)` }}
    >
      {legend?.map((item) => (
        <div key={item.order} className={styles.heatmap__item}>
          <p>{item.description}</p>
          <div
            className={styles.heatmap__block}
            style={
              {
                '--heatmap-color':
                  heatmapColors[colorIndex || 0]?.[item.order - 1]
              } as React.CSSProperties
            }
          />
        </div>
      ))}
    </div>
  );
}

export default Heatmap;
