import { config } from '@config';
import { Locales } from '@xq/ui-kit';

export const getCurrentLanguage = (): Locales => {
  let language = window.localStorage.getItem('lang');

  if (!language) {
    language = config.defaultLang;
  }
  return language as Locales;
};

export const noop = () => {};
